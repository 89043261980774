import { render, staticRenderFns } from "./CognitiveTYCS.vue?vue&type=template&id=ed7890a8&scoped=true"
import script from "./CognitiveTYCS.vue?vue&type=script&lang=js"
export * from "./CognitiveTYCS.vue?vue&type=script&lang=js"
import style0 from "./CognitiveTYCS.vue?vue&type=style&index=0&id=ed7890a8&prod&scoped=true&lang=css"
import style1 from "./CognitiveTYCS.vue?vue&type=style&index=1&id=ed7890a8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed7890a8",
  null
  
)

export default component.exports