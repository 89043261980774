<template>
    <div class="Exercisetesting clearfix">
        <div class="Exercise_div">
            <!-- <div class="tui_left fl">
                <ul class="le_one">
                    <li>
                        <p class="le_one_p">
                            {{forms.rule.values[0].name}}
                        </p>
                        <div class="le_one_div">
                            <span>{{forms.rule.values[0].desc}}</span>
                        </div>
                    </li>
                </ul>
            </div> -->
            <div class="tui_right fl">
                <div class="upload_div">
                    <div class="cds_video">
                        <video-player
                            class="video-player vjs-custom-skin"
                            ref="videoPlayer"
                            :playsinline="true"
                            :options="playerOptions"
                            @play="onPlayerPlay($event)"
                            @pause="onPlayerPause($event)"
                            @ended="onPlayerEnded($event)"
                        ></video-player>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
    components: {
        videoPlayer
    },
    data() {
        return {
            ruleForm:{
                role1:'',
                role2:'',
                role3:'',
                role4:'',
                role5:''
            },
            forms:{
                rule:{
                    values:[
                        {}
                    ]
                },
                p1:{},
                p2:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                },
                user:{
                    values:[]
                }
            },
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: "zh-CN",
                    width: "100%",
                    aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [
                        {
                            type: "video/mp4", // 类型
                            src: "" // url地址
                        }
                    ],
                    poster:'', // 封面地址
                    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects7({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    var str = this.form
                    this.$emit('info', str);
                    this.forms = res.data.data.data
                    this.playerOptions.sources[0].src = this.forms.p1.value;
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveTYCS.css';
</style>
<style>
.cds_video .video-js{
    height: 100%!important;
}
.cds_video .video-js .vjs-mute-control{
    padding-left: 0;
}
.cds_video .video-js .vjs-control{
    width: 2.5em;
}
</style>